import React, { useEffect } from 'react';
import HtmlHead from '../components/html-head';
import HeroLandingComponent from '../components/landing-components/hero-landing';
import LandingNav from '../components/landing-components/nav.tsx';
import AuthorSection from '../components/landing-components/author-section';
import heroimg from '../assets/landing-page/other-landing-page-img.png';
import catherine from '../assets/landing-page/Catherine Coleman.png';
import charles from '../assets/landing-page/Charles Coaxum.png';
import everbridge from '../assets/landing-page/everbridge.png';
import cisco from '../assets/landing-page/cisco.png';
import chris from '../assets/landing-page/Chris.png';
import adam from '../assets/landing-page/Adam.png';
import hexagon from '../assets/landing-page/hexagon.png';
import cority from '../assets/landing-page/cority.png';
import LandingFooter from '../components/landing-components/landing-footer';
import LandingCtaBlock from '../components/landing-components/landing-cta';
import { graphql } from 'gatsby';
import { PageData } from '../types';

const OtherLandingPage: React.FC<PageData> = (resp) => {
    const seoData = resp?.data?.allContentfulPageSeoData?.edges[0]?.node;

    const hero = {
        formName: 'ai-powered-customer-support',
        mainTitle : 'AI For Customer Support',
        title: 'Search Less, <span>AnswerGPT</span> More',
        image: heroimg,
        formId: '221f5f57-c546-4b56-8994-60548dbace1b'
    };

    const author = [
        {
            name: '<span>Catherine</span> Coleman',
            title: 'Former VP Global Customer Support',
            quote: "With AptEdge, we’re delivering higher quality answers, reaching <span class='quote-num'>99% </span><span class='quote-imp'>CSAT</span>",
            photo: catherine,
            company: everbridge
        },
        {
            name: '<span>Charles</span> Coaxum',
            title: 'Former Head of CS Security',
            quote: "AptEdge provides fast accurate answers, improving <span class='quote-imp'>Resolution Time</span> up by <span class='quote-num'>40% </span>",
            photo: charles,
            company: cisco
        },
        {
            name: '<span>Chris</span> Slaugh',
            title: 'VP Global Customer Support',
            quote: "Now our new analysts come on board and they hit that <span class='quote-imp'>Time-to-Productivity</span> number within 2 months.",
            photo: chris,
            company: cority
        },
        {
            name: '<span>Adam</span> Savage',
            title: 'Chief Operating Officer',
            quote: "Our <span class='quote-imp'>Agent Efficiency</span> is up by <span class='quote-num'>50% </span> with AptEdge, reducing headcount and onboarding costs",
            photo: adam,
            company: hexagon
        },
    ]

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <LandingNav/>
            <HeroLandingComponent hero={hero} />
            <AuthorSection author={author}/>
            <LandingCtaBlock/>
            <LandingFooter/>
            {/* @ts-ignore */}
            <style jsx>
                {
                `.intercom-lightweight-app {
                    display: none !important;
                }`
                }
            </style>
        </>
    );
}

export default OtherLandingPage;

export const query = graphql`
    query OtherLandingPageQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/ai-powered-customer-support" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;